import React from 'react'
import { Link } from 'react-router-dom'
import ExplorePlanTabs from './ExplorePlansTabs'
import { IoMdArrowForward } from "react-icons/io";

function OzenBolifushiPlan() {
    const exploreHeading = 'The Reserve Plan'
    return (
        <>
            <section>
                <div className='explore-kanifushi-plan'>
                    <div className='container-fluid'>
                        <div className='row'>
                            <div className='col-lg-7'>
                                <div className='hotel-heading-wrap mb-lg-5 mb-4 p-0'>
                                    <h2 className='text-uppercase ps-0 heading-space mb-0'>The Reserve<sup className='fs-4'>TM</sup> Plan</h2>
                                    <p className='mt-4'>This one-of-a-kind, bespoke, and indulgent vacation plan is exclusive to OZEN RESERVE BOLIFUSHI. Its privileges encompass inspiring fine dining, premium beverages, and exclusive experiences, blended seamlessly with your stay for a lavish holiday experience. </p>
                                </div>
                            </div>
                            <div className='col-lg-5'>
                                <div className='hotel-heading-wrap mb-lg-5 p-0 mb-4 d-flex align-items-center justify-content-end'>
                                    <Link to='' className='btn theme-btn-outline btn-outline-success fw-light text-text-capitalize border-theme'>View Reseve Plan <IoMdArrowForward /></Link>
                                </div>
                            </div>
                        </div>
                        
                        <div className='d-flex flex-wrap'>
                            <ExplorePlanTabs/>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default OzenBolifushiPlan
