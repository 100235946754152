import './App.css';
import Header from './components/Header';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/scss/style.scss'
import './assets/scss/responsive.scss'
import '../node_modules/react-tabs-scrollable/dist/rts.css';
import AllRoutes from './allRoutes/AllRoutes';
import 'react-toastify/dist/ReactToastify.css';
import { Home } from './pages/home/Home';

function App() {
  return (
    <>
      <Header/>
      <AllRoutes/>
    </>
  );
}

export default App;
