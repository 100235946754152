import React from 'react'
import Accordion from 'react-bootstrap/Accordion';

function PlanAccordion({planTabHeading,planEventKey,planTabText}) {
  return (
    <>        
        <Accordion.Item eventKey={planEventKey}>
            <Accordion.Header>{planTabHeading}</Accordion.Header>
            <Accordion.Body>
                <p>{planTabText}</p>
            </Accordion.Body>
        </Accordion.Item>
    </>
  )
}

export default PlanAccordion