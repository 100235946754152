import React from 'react'
import PlanAccordion from './PlanAccordion'
import { Accordion } from 'react-bootstrap'

function PlanTabItem({accordionData, tabFeatureImage, planFaqHeading}) {
    const defaultActiveKey = accordionData.length > 0 ? accordionData[0].planEventKey : null;
  return (
    <>
        <div className='explore-plan-tabs d-flex flex-wrap'>
            <div className='plan-tab-feature-img'>
                <div className='tab-feature-image'>
                    <img src={tabFeatureImage} alt={planFaqHeading} title={planFaqHeading} />
                </div>
            </div>
            <div className='plan-tab-faqs'>
                <div className='plan-questions'>
                    <div className='tab-heading-wrapper'>
                        <h3 className='text-uppercase fw-light'>{planFaqHeading}</h3>
                    </div>
                    <Accordion defaultActiveKey={defaultActiveKey}>
                    {accordionData.map(accData=>(
                        <PlanAccordion key={accData.planEventKey} planTabHeading={accData.planTabHeading} planEventKey={accData.planEventKey} planTabText={accData.planTabText} />
                    ))}
                    </Accordion>
                </div>
            </div>
        </div>
    </>
  )
}

export default PlanTabItem