import React from 'react'
import HomeBanner from '../../components/HomeBanner'
import ExperienceAwait from '../../components/ExperienceAwait'
import OzenBolifushiPlan from '../../components/OzenBolifushiPlan'

export const Home = () => {
    const smallHeading = 'OUR RESORT'
    const para = `<p>Every dream you’ve had of Maldives 5-star resorts comes alive at OZEN RESERVE BOLIFUSHI. Surrounded by a gorgeous natural reef and turquoise waters, the private island resort exudes elegance, style, and timeless sophistication. From fabulous overwater villas with slides to exotic fine dining, every experience is meticulously crafted to indulge you with an unforgettable getaway.</p>`;
  return (
    <>
        <HomeBanner/>
        <ExperienceAwait para={para} smallHeading={smallHeading}/>
        <OzenBolifushiPlan />
    </>
  )
}
