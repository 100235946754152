import React from 'react'
import bannerBg from '../assets/images/bolifushi-bg.jpg'

function HomeBanner() {
  return (
    <>
        <section>
            <div className='atmosphere-hero-video position-relative'>
                <img src={bannerBg} alt='banner' title='banner' />
            </div>
        </section>
    </>
  )
}

export default HomeBanner
