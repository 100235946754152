import React from 'react'
import { Link, NavLink } from 'react-router-dom';
import menuImg from '../assets/images/menu.svg'
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/Button';
import logo from '../assets/images/logo-icon.svg'
import logoWithCircle from '../assets/images/logo.svg'
import { useState,useRef, useEffect} from 'react';
const Header = () => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [expanded, setExpanded] = useState(false);
    const navbarRef = useRef(null);
    const handleClickOutside = (event) => {
        if (navbarRef.current && !navbarRef.current.contains(event.target)) {
            setExpanded(false);
        }
    };
//     const handleScroll = () => {
//         setExpanded(false);
//         setIsMobile(window.innerWidth <= 767 && window.scrollY === 0);
//     };
//     useEffect(() => {
//       document.addEventListener('click', handleClickOutside);
//       window.addEventListener('scroll', handleScroll);
//       return () => {
//           document.removeEventListener('click', handleClickOutside);
//           window.removeEventListener('scroll', handleScroll);
//       };
//   }, []);
  return (
    <>
        <header className='start-0 top-0 end-0 z-3'>
            <div className='header-wrap position-relative'>
                <div className='container-fluid'>
                    <div className='row position-relative'>
                        <button className='menu-btn border-0 bg-transparent position-absolute z-2 d-none'>
                            <img src={menuImg} alt='Menu' title='Menu' />
                        </button>
                        {/* /col */}
                        <div className='col d-xl-none'>
                            <div className='logo-device-col'>
                                <div className='device-logo position-relative'>
                                    <Link to='/'><img src={logo} title='Atmosphere Living' alt='Atmosphere Living' /></Link>
                                </div>
                                <div className='mobile-book-now d-xl-none'>
                                    <Button className='btn outline-success text-white book-btn text-uppercase px-4 py-2' onClick={handleShow}>Book Now</Button>
                                </div>
                            </div>
                        </div>
                        <div className='col-12'>
                            <div className="atmosphere-living-nav" ref={navbarRef}>
                            <Navbar expanded={expanded} expand="xl" className="navbar-nav smooth">
                                <Navbar.Toggle className="shadow-none"aria-controls="basic-navbar-nav" onClick={()=>setExpanded(prev=>!prev)} />
                                <Navbar.Collapse className='w-100' id="basic-navbar-nav">
                                <Nav as='ul' className="nav-ul start-nav-list me-auto d-flex align-items-center text-uppercase">
                                    <Nav.Item onClick={() => setExpanded(!expanded)} as='li'><NavLink className='text-white nav-link' to='/'><span>Home</span></NavLink></Nav.Item>
                                    <Nav.Item onClick={() => setExpanded(!expanded)}   as='li'><NavLink className='text-white nav-link' to="/our-villas"><span>VILLAS</span></NavLink></Nav.Item>
                                    <Nav.Item onClick={() => setExpanded(!expanded)} as='li'><NavLink className='text-white nav-link' to="/our-plans"><span>THE PLAN</span></NavLink></Nav.Item>
                                    <Nav.Item onClick={() => setExpanded(!expanded)} as='li'><NavLink className='text-white nav-link' to="/our-dining"><span>DINING</span></NavLink></Nav.Item>
                                    <Nav.Item onClick={() => setExpanded(!expanded)} className='logo-left-item' as='li'><NavLink className='text-white nav-link' to="/akiri-spa"><span>AKIRI SPA</span></NavLink></Nav.Item>
                                    </Nav>
                                    <div className='atmosphere-centered-logo ms-auto me-auto'>
                                        <Link onClick={() => setExpanded(!expanded)} className='text-white position-relative d-flex' to="/">
                                            <img className='position-absolute end-0 start-0 m-auto logo-fixed smooth' src={logo} title='Atmosphere Living' alt='Atmosphere Living' />
                                            <img className='position-absolute end-0 logo-sticky smooth' src={logoWithCircle} title='Atmosphere Living' alt='Atmosphere Living' />
                                        </Link>
                                    </div>
                                    <Nav as='ul' className="nav-ul end-nav-list me-auto d-flex align-items-center text-uppercase">
                                    <Nav.Item onClick={() => setExpanded(!expanded)} as='li' className='logo-right-item'><NavLink className='text-white nav-link' to="/offers"><span>OFFERS</span></NavLink></Nav.Item>
                                    <Nav.Item onClick={() => setExpanded(!expanded)} as='li'><NavLink className='text-white nav-link'  to='/experiences'><span>EXPERIENCES</span></NavLink></Nav.Item>
                                    <Nav.Item onClick={() => setExpanded(!expanded)} as='li'><NavLink className='text-white nav-link'  to='/sustainability'><span>SUSTAINABILITY</span></NavLink></Nav.Item>
                                    <Nav.Item onClick={() => setExpanded(!expanded)} as='li'><NavLink className='text-white nav-link'  to='/gallery'><span>GALLERY</span></NavLink></Nav.Item>
                                    <Nav.Item onClick={() => setExpanded(!expanded)} className='booking-nav-item d-none d-lg-flex' as='li'>
                                    <Button className='btn outline-success book-btn text-uppercase px-xl-4 px-3 py-2' onClick={handleShow}>Book Now</Button></Nav.Item>
                                    {/* <Modals show={show} handleClose={handleClose}/> */}
                                </Nav>
                                </Navbar.Collapse>
                            </Navbar>
                            </div>
                            {/* /atmosphere-living-nav */}
                        </div>
                        {/* /col */}
                    </div>
                    {/* /row */}
                </div>
                {/* container-fluid */}
            </div>
        </header>
    </>
  )
}
export default Header