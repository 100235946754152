import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import resort from '../assets/images/palm-tree.svg'
import parse from 'html-react-parser'
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import sustainabilityManagementPdf from '../assets/documents/AKM-Sustainability-2023.pdf';
import environmentalPolicyPdf from '../assets/documents/AKM-Environmental-2023.pdf';
import { MdArrowOutward } from 'react-icons/md';
import { IoMdArrowForward } from "react-icons/io";
import btnImage from '../assets/images/360-degree.svg'


function ExperienceAwait({smallHeading,sectionClass,heading="Refined Elegance", para,image=resort,akiraMenuPDF,showLearnMore=true, showHeading=true, visitLinks=false,showLargeheading=false, showMenu=false}) {
  return (
    <>
        <section>
            <div className={`atmosphere-experience-await section-padding ${sectionClass}`}>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12 text-center'>
                            {showHeading? (<div className='small-heading'><span>{smallHeading}</span>
                            </div>):("")}               
                            {showLargeheading?
                                (<h2 className='m-auto text-uppercase heading-space mt-xl-4 mt-2'>{heading}</h2>): 
                                (<h1 className='m-auto text-uppercase heading-space mt-xl-4 mt-2 h2'>{heading}</h1>)
                            }
                        </div>
                        <div className='col-12 text-center'>
                            <div className='paragraph m-auto pt-3 text-center'>
                                {parse(para)}
                            </div>
                            {showLearnMore? (<div className='action-btn d-none mt-lg-5 mt-4 text-center'>
                                <Link to='/our-story' className="btn theme-btn fw-medium text-white text-uppercase border-theme">Our Story</Link>
                            </div>):("")}

                            <Link to='' className='btn theme-btn-outline btn-outline-success fw-light text-capitalize border-theme'><img src={btnImage} alt='360' title='360' className='' /> View <MdArrowOutward /></Link>

                            {showMenu?(<Link to={akiraMenuPDF} target='_blank' className='mt-lg-5 mt-4 georgia w-auto btn theme-btn-outline btn-outline-success fw-medium text-text-capitalize border-theme'>View Menu <MdArrowOutward className='ms-2' /></Link>):("")}

                            {visitLinks? (<div className='site-visit-links d-flex flex-wrap justify-content-center mt-xl-5 mt-4'>
                                <Link to={sustainabilityManagementPdf} target="_blank" className="georgia btn theme-btn-outline btn-outline-success fw-medium text-text-capitalize border-theme">Sustainability Management <FontAwesomeIcon icon={faArrowUp} className='ms-2' /></Link>
                                <Link to={environmentalPolicyPdf} target="_blank" className="georgia btn theme-btn-outline btn-outline-success fw-medium text-text-capitalize border-theme ms-md-2 ms-lg-4 ms-0 mt-md-0 mt-3">Environmental Policy <FontAwesomeIcon icon={faArrowUp} className='ms-2' /></Link>
                            </div>):("")}
                            
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default ExperienceAwait